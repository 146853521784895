var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"meu"},[_c('div',{staticClass:"meuTop",on:{"click":_vm.toLogin}},[_vm._m(0),_c('div',{staticClass:"userInfo"},[_c('div',{staticClass:"user"},[_vm._v(_vm._s(_vm.userName))]),(_vm.isok)?_c('div',{staticClass:"userID"},[_vm._v(" ID: "),_c('span',[_vm._v(_vm._s(_vm.userId))])]):_vm._e()])]),(!_vm.isok)?_c('div',{staticClass:"advertising"},[_vm._m(1),_vm._m(2)]):_vm._e(),_c('div',[_c('div',{staticClass:"cells",on:{"click":function($event){return _vm.toViewingHistory()}}},[_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"cells",on:{"click":function($event){return _vm.toOpiniao()}}},[_vm._m(5),_vm._m(6)])]),_c('layout')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('img',{staticStyle:{"width":"50px","height":"50px"},attrs:{"src":require("../../assets/ic_avatar_default.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advertisingTop"},[_c('div',[_vm._v("Publicidade gratuita")]),_c('div',[_vm._v("A publicidade é inevitável")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advertisingCard"},[_c('div',[_vm._v("0 pessoa já indicada")]),_c('div',[_vm._v("extensão")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cellsLeft"},[_c('div',[_c('img',{staticStyle:{"width":"28px","height":"28px"},attrs:{"src":require("../../assets/ic_home_history.png"),"alt":""}})]),_c('div',[_vm._v("History")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticStyle:{"width":"8px","height":"16px"},attrs:{"src":require("../../assets/ic_forward.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cellsLeft"},[_c('div',[_c('img',{staticStyle:{"width":"28px","height":"28px"},attrs:{"src":require("../../assets/ic_feedback.png"),"alt":""}})]),_c('div',[_vm._v("Feedback")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticStyle:{"width":"8px","height":"16px"},attrs:{"src":require("../../assets/ic_forward.png"),"alt":""}})])
}]

export { render, staticRenderFns }