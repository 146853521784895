<template>
  <div class="meu">
    <!-- to="/login" -->
    <div class="meuTop" @click="toLogin">
      <div class="head">
        <img src="../../assets/ic_avatar_default.png" alt="" style="width: 50px; height: 50px;">
      </div>
      <div class="userInfo">
        <div class="user">{{ userName }}</div>
        <div class="userID" v-if="isok">
          ID: <span>{{ userId }}</span>
        </div>
      </div>
    </div>
    <!-- 广告位 -->
    <div class="advertising" v-if="!isok">
      <div class="advertisingTop">
        <div>Publicidade gratuita</div>
        <div>A publicidade é inevitável</div>
      </div>
      <div class="advertisingCard">
        <div>0 pessoa já indicada</div>
        <div>extensão</div>
      </div>
    </div>
    <!-- 设置 -->
    <div>
      <div class="cells" @click="toViewingHistory()">
        <div class="cellsLeft">
          <div><img src="../../assets/ic_home_history.png" alt="" style="width: 28px; height: 28px;"></div>
          <div>History</div>
        </div>
        <div>
          <img src="../../assets/ic_forward.png" alt="" style="width: 8px; height: 16px;">
        </div>
      </div>
<!--      <div class="cells" @click="toMeuDownload()">-->
<!--        <div class="cellsLeft">-->
<!--          <div><img src="../../assets/组1651.png" alt=""></div>-->
<!--          <div>Meu download</div>-->
<!--        </div>-->
<!--        <div>-->
<!--          <img src="../../assets/ic_forward.png" alt="">-->
<!--        </div>-->
<!--      </div>-->

      <div class="cells" @click="toOpiniao()">
        <div class="cellsLeft">
          <div><img src="../../assets/ic_feedback.png" alt="" style="width: 28px; height: 28px;"></div>
          <div>Feedback</div>
        </div>
        <div>
          <img src="../../assets/ic_forward.png" alt="" style="width: 8px; height: 16px;">
        </div>
      </div>

    </div>

<!--    <div class="cells" @click="toConfigurar">-->
<!--      <div class="cellsLeft">-->
<!--        <div><img src="../../assets/蒙版组29.png" alt=""></div>-->
<!--        <div>Configurar</div>-->
<!--      </div>-->
<!--      <div>-->
<!--        <img src="../../assets/ic_forward.png" alt="">-->
<!--      </div>-->
<!--    </div>-->

    <layout></layout>
  </div>
</template>
import layout from '@/layout/index' //轮播图组件页
<script>
import layout from "@/layout";

export default {
  name: '',
  components: {
    layout
  },
  data() {
    return {
      isok: false,
      userName: 'Freecine',
      userId: null
    }
  },
  // 计算属性
  computed: {},
  watch: {},
  mounted() {
    this.getuserInfo()
  },
  methods: {
    toViewingHistory() {
      this.$router.push('/ViewingHistory')
    },
    toMeuDownload() {
      this.$router.push('/meuDownload')
    },
    toOpiniao() {
      this.$router.push('/feedback')
    },
    toConfigurar() {
      this.$router.push('/configurar')
    },
    // 用户信息
    getuserInfo() {
      const userInfo = JSON.parse(localStorage.getItem('ueserInfo'));
      if (userInfo) {
        this.userName = userInfo.nickname.replace('游客', 'Visitor:')
        this.userId = userInfo.user_id
        if (userInfo.token != null) {
          this.isok = true
        }
      }
    },
    toLogin() {
      const userInfo = JSON.parse(localStorage.getItem('ueserInfo'));
      if (userInfo.login_type == 0) {
        this.$router.push('/login')
      }

    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/meu.css';

//头部样式
.meuTop {
  height: 20vh;
  background-color: #1c202b;
  padding-left: 20px;
  display: flex;
  align-items: center;
  position: relative;
}

.head {
  width: 64px;
  height: 64px;
  padding-right: 10px;
}

.userInfo {
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 1px;
  color: var(--title-color);
  opacity: 0.8;
}

.user {
  padding-bottom: 10px;
}

.userID {
  font-size: 14px;
  opacity: 0.7;
}

// 广告样式
.advertising {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 19px;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 0.8);
  padding: 15px;
  margin: 0 5px 10px 0px;
}

.advertisingTop {
  display: flex;
  justify-content: space-between;
}

.advertisingTop div:nth-of-type(2) {
  font-size: 12px;
  color: var(--minor-text-color);
}

.advertisingCard {
  background-color: #b10616;
  box-shadow: 0px 4px 6px 0px rgba(239, 35, 60, 0.74);
  border-radius: 9px;
  padding: 30px 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.advertisingCard div:nth-of-type(2) {
  background-color: var(--title-color);
  border-radius: 14px;
  color: #ef233c;
  font-size: 14px;
  padding: 6px 13px;
}
</style>
